<template>
  <Page id="contact" anchor="contact" :class="mq">
    <div id="contact-content">
      <div class="contact-left">
        <div class="contact-column">
          <h1>Have a project? <span class="highlight">Get in touch!</span></h1>
          <p>
            Contact us to get a quote, see how we can help your business or join
            the team!
          </p>
        </div>
        <div class="contact-column">
          <div>
            <h3>Email us:</h3>
            <a href="MAILTO:enquiries@c-clarity.co.uk">
              enquiries@c-clarity.co.uk
            </a>
          </div>
          <div>
            <h3>Phone us:</h3>
            <a href="tel:+447721655007">07721655007</a>
          </div>
        </div>
        <Socials />
      </div>
    </div>

    <div v-if="!isMobile" />
  </Page>
</template>

<script>
import Socials from "@/components/global/Socials.vue";
import Page from "@/components/core/Page.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: {
    Socials,
    Page,
  },
};
</script>

<style scoped>
#contact-content {
  padding: 0 var(--padding-mobile) 0 var(--padding-mobile);
  align-items: flex-start;
  justify-content: center;
  display: flex;
  width: 100%;
  gap: 60px;
}

.brTablet #contact-content,
.brMobile #contact-content {
  flex-direction: column;
  padding: unset;
}

.contact-left {
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  display: flex;
  gap: 80px;
}

.brTablet .contact-left,
.brMobile .contact-left {
  padding: 0 var(--padding-mobile) 0 var(--padding-mobile);
}

.brMobile .contact-left {
  margin: unset;
  gap: 40px;
}

.contact-column,
.contact-column > div {
  flex-direction: column;
  display: flex;
  gap: 15px;
}

.contact-column > div {
  flex-direction: row;
  gap: 15px;
}

.contact-column a {
  color: var(--accent);
}
</style>
